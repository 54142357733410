import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-caad2b78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex container-fluid g-0 min-vh-100 bg-light" }
const _hoisted_2 = { class: "d-flex flex-column w-100 position-relative" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = { class: "col-10 col-xl-8" }
const _hoisted_6 = {
  key: 3,
  class: "mb-32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_BannerTitle = _resolveComponent("BannerTitle")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_TemplateQuote = _resolveComponent("TemplateQuote")!
  const _component_TemplateVideosScript = _resolveComponent("TemplateVideosScript")!
  const _component_Templates = _resolveComponent("Templates")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-outline-white position-absolute z-index-1 top-12 start-12",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToAcademyTemplates && _ctx.goToAcademyTemplates(...args)))
      }, [
        _createVNode(_component_Fa, { icon: "arrow-left" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('templates.actions.backToOverview')), 1)
      ]),
      _createVNode(_component_WaitForResource, {
        class: "h-100",
        resource: _ctx.template
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BannerTitle, {
            class: "mh-90vh mb-12",
            template: _ctx.template
          }, null, 8, ["template"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.template.body)
                  ? (_openBlock(), _createBlock(_component_Markdown, {
                      key: 0,
                      class: "mb-20",
                      source: _ctx.template.body
                    }, null, 8, ["source"]))
                  : _createCommentVNode("", true),
                (_ctx.template.author && _ctx.template.quote)
                  ? (_openBlock(), _createBlock(_component_TemplateQuote, {
                      key: 1,
                      author: _ctx.template.author,
                      body: _ctx.template.quote,
                      class: "mb-32"
                    }, null, 8, ["author", "body"]))
                  : _createCommentVNode("", true),
                (_ctx.template.subjects && _ctx.template.subjects.count())
                  ? (_openBlock(), _createBlock(_component_TemplateVideosScript, {
                      key: 2,
                      class: "mb-32",
                      subjects: _ctx.template.subjects,
                      onWatchResource: _ctx.watchResource
                    }, null, 8, ["subjects", "onWatchResource"]))
                  : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('templates.index.noMediaBody')), 1)),
                (_ctx.template.author && _ctx.template.author.quote)
                  ? (_openBlock(), _createBlock(_component_TemplateQuote, {
                      key: 4,
                      author: _ctx.template.author,
                      body: _ctx.template.author.quote,
                      class: "mb-32"
                    }, null, 8, ["author", "body"]))
                  : _createCommentVNode("", true),
                (_ctx.template.id)
                  ? (_openBlock(), _createBlock(_component_Templates, {
                      key: 5,
                      class: "mb-12",
                      "exclude-id": _ctx.template.id,
                      "hide-if-empty": "",
                      preview: "",
                      "random-order": "",
                      onViewAll: _ctx.goToAcademyTemplates
                    }, null, 8, ["exclude-id", "onViewAll"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["resource"])
    ])
  ]))
}