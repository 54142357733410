
import {PropType, defineComponent} from 'vue';
import ImageOverlay from '@/components/common/ImageOverlay.vue';
import Template from '@/models/Template';

export default defineComponent({
    components: {
        ImageOverlay,
    },
    props: {
        template: {
            type: Object as PropType<Template>,
            required: true,
        },
    },
    computed: {
        banner(): string | undefined {
            return this.template.banner?.banner;
        },
        overlayImage(): string | undefined {
            return this.template.hoverImage?.hoverImage;
        },
    },
    methods: {
        goToTemplate() {
            this.$router.push(this.template.getLocation('show'));
        },
    },
});
