import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row align-items-center mb-8" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "ms-6" }
const _hoisted_5 = ["data-bs-target"]
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "d-inline-block fw-bold min-w-100px text-end" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = ["data-test-id", "onClick"]
const _hoisted_12 = { class: "col ms-6" }
const _hoisted_13 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('templates.index.courseCurriculum')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('templates.index.lessonsCount', [_ctx.subjectsWithResources.totalMediaCount])) + " ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('templates.index.durationMinutes', [_ctx.subjectsWithResources.formattedTotalMediaDuration])), 1)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjectsWithResources, (subject) => {
      return (_openBlock(), _createBlock(_component_Collapse, {
        key: `subject-${subject.id}`,
        class: "mb-4 rounded bg-white",
        name: `subject-${subject.id}-resources`
      }, {
        "collapse-trigger": _withCtx(({target}) => [
          _createElementVNode("div", {
            class: "collapse-trigger px-12 py-6 cursor-pointer collapsed row align-items-center",
            "data-bs-target": target,
            "data-bs-toggle": "collapse"
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Fa, {
                class: "collapse-trigger-arrow",
                icon: "chevron-down"
              })
            ]),
            _createElementVNode("h4", _hoisted_7, _toDisplayString(subject.name), 1),
            _createElementVNode("div", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t('templates.index.lessonsCount', [subject.mediaResources.count()])) + " ", 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('templates.index.durationMinutes', [subject.mediaResources.formattedTotalMediaDuration])), 1)
            ])
          ], 8, _hoisted_5)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subject.mediaResources, (resource, rIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `subject-${subject.id}-resource-${resource.id}`,
              class: "gx-0 px-12 py-6 row align-items-center border-top"
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("button", {
                  class: "btn btn-square btn-sm btn-light rounded-circle d-flex align-items-center justify-content-center",
                  "data-test-id": `btn-subject-${subject.id}-resource-${resource.id}`,
                  onClick: ($event: any) => (_ctx.$emit('watch-resource', resource.id))
                }, [
                  _createVNode(_component_Fa, {
                    icon: "play-solid",
                    size: "lg"
                  })
                ], 8, _hoisted_11)
              ]),
              _createElementVNode("div", _hoisted_12, _toDisplayString(`${rIndex + 1}.`) + " " + _toDisplayString(resource.title), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('templates.index.durationMinutes', [resource.formattedMediaDuration])), 1)
            ]))
          }), 128))
        ]),
        _: 2
      }, 1032, ["name"]))
    }), 128))
  ]))
}