
import {PropType, defineComponent} from 'vue';
import Subject, {Subjects} from '@/models/Subject';
import Collapse from '@/components/common/Collapse.vue';

export default defineComponent({
    components: {
        Collapse,
    },
    props: {
        subjects: {
            type: Object as PropType<Subjects>,
            required: true,
        },
    },
    emits: [
        'watch-resource',
    ],
    computed: {
        subjectsWithResources(): Subjects {
            return this.subjects.filter((subject: Subject) => {
                return subject.mediaResources.count() > 0;
            });
        },
    },
});
