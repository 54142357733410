
import {defineComponent} from 'vue';
import i18n from '@/vendor/I18n';

/**
 * Creates a hover overlay with a button over an image.
 * The image that is overlaid can be overridden by using the image slot.
 * Emits 'image-clicked' when the user clicks anywhere on the image or overlay.
 */
export default defineComponent({
    props: {
        actionTitle: {
            type: String,
            default: i18n.global.t('actions.explore'),
        },
        image: {
            type: String,
            default: '',
        },
        overlayImage: {
            type: String,
            default: '',
        },
    },
    emits: [
        'image-clicked',
    ],
});
