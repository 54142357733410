
import {PropType, defineComponent} from 'vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import User from '@/models/User';

export default defineComponent({
    components: {
        ProfilePicture,
    },
    props: {
        author: {
            type: Object as PropType<User>,
            required: true,
        },
        body: {
            type: String,
            required: true,
        },
    },
});
