import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex fs-5 mb-6" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "ms-12 text-muted" }
const _hoisted_5 = {
  ref: "templatesContainer",
  class: "row"
}
const _hoisted_6 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewTemplate = _resolveComponent("ViewTemplate")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _directive_infinite_scroll_collection = _resolveDirective("infinite-scroll-collection")!

  return (!_ctx.hideTemplates)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('academy.templates.title')), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('academy.templates.templatesCount', [_ctx.templates.total])), 1),
          (_ctx.preview)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "link-dark ms-auto",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view-all')))
              }, _toDisplayString(_ctx.$t('academy.actions.viewAll')), 1))
            : _createCommentVNode("", true)
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slicedTemplates, (template) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `template-${template.id}`,
              class: "col-4 mb-12"
            }, [
              _createVNode(_component_ViewTemplate, { template: template }, null, 8, ["template"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.templates.loading)
              ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ])), [
          [
            _directive_infinite_scroll_collection,
            {collection: _ctx.templates},
            void 0,
            { document: true }
          ]
        ])
      ]))
    : _createCommentVNode("", true)
}