
import {defineComponent, ref} from 'vue';
import BannerTitle from '@/components/templates/BannerTitle.vue';
import Markdown from '@/components/common/Markdown.vue';
import Template from '@/models/Template';
import TemplateQuote from '@/components/templates/Quote.vue';
import TemplateVideosScript from '@/components/templates/VideosScript.vue';
import Templates from '@/components/academy/Templates.vue';

export default defineComponent({
    components: {
        BannerTitle,
        Markdown,
        TemplateQuote,
        TemplateVideosScript,
        Templates,
    },
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        return {
            template: ref<Template>(new Template({
                slug: props.slug,
            })),
        };
    },
    created() {
        this.template.fetch();
    },
    methods: {
        goToAcademyTemplates() {
            this.$router.push({
                name: 'academy',
                query: {
                    view: 'templates',
                },
            });
        },
        watchResource(resourceId: number) {
            const templateLocation = this.template.getLocation(
                'script',
                {
                    resourceId,
                },
            );

            this.$router.push(templateLocation);
        },
    },
});
