import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e4830da"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "d-flex justify-content-center align-items-center overlay transition rounded position-absolute inset-0 overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "overlay-backdrop position-absolute inset-0"
}
const _hoisted_5 = { class: "btn btn-light position-absolute" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "overlay-wrapper position-relative cursor-pointer",
    "data-test-id": "image-overlay-wrapper",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('image-clicked')))
  }, [
    _renderSlot(_ctx.$slots, "image", { image: _ctx.image }, () => [
      _createElementVNode("img", {
        class: "w-100 rounded",
        src: _ctx.image
      }, null, 8, _hoisted_1)
    ], true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.overlayImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-100",
            src: _ctx.overlayImage
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", _hoisted_4)),
      _createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.actionTitle), 1)
    ])
  ]))
}