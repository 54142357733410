
import {PropType, defineComponent} from 'vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import Template from '@/models/Template';

export default defineComponent({
    components: {
        ProfilePicture,
    },
    props: {
        template: {
            type: Object as PropType<Template>,
            required: true,
        },
    },
    computed: {
        banner(): string | undefined {
            return this.template.banner?.original;
        },
    },
});
