import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-10 text-primary" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "ms-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Fa, {
      class: "mb-10",
      icon: "quote-left",
      size: "3x"
    }),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.body), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProfilePicture, {
        class: "square-xl rounded-circle",
        "initial-class": "display-6",
        "src-key": "thumbSm",
        user: _ctx.author
      }, null, 8, ["user"]),
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.author.name), 1)
    ])
  ]))
}