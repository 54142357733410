import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e57283e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-6 mb-2" }
const _hoisted_2 = { class: "text-muted mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageOverlay = _resolveComponent("ImageOverlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ImageOverlay, {
      image: _ctx.banner || require('@/assets/images/template-thumbnail.png'),
      "overlay-image": _ctx.overlayImage,
      onImageClicked: _ctx.goToTemplate
    }, null, 8, ["image", "overlay-image", "onImageClicked"]),
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.template.name), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('templates.view.videosCount', [_ctx.template.videosCount])), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.template.introduction), 1)
  ]))
}