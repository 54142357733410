import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4de1c609"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-flex flex-column h-100 justify-content-evenly align-items-center text-white inset-center" }
const _hoisted_4 = { class: "fs-4" }
const _hoisted_5 = { class: "font-akira display-1 text-center" }
const _hoisted_6 = {
  key: 0,
  class: "text-center"
}
const _hoisted_7 = { class: "mt-4 fs-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "h-100 w-100 object-cover bg-black",
      src: _ctx.banner || require('@/assets/images/template-banner.png')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('templates.index.videosCount', [_ctx.template.videosCount])), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.template.name), 1),
      (_ctx.template.author)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_ProfilePicture, {
              class: "square rounded-circle",
              user: _ctx.template.author
            }, null, 8, ["user"]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('templates.index.byUser', [_ctx.template.author.name])), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}