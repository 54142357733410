
import {defineComponent, ref} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import {CollectionData} from '@/models/Collection';
import {Templates} from '@/models/Template';
import ViewTemplate from '@/components/templates/View.vue';

export default defineComponent({
    components: {
        ViewTemplate,
    },
    props: {
        excludeId: {
            type: Number,
            default: null,
        },
        hideIfEmpty: {
            type: Boolean,
            default: false,
        },
        preview: {
            type: Boolean,
            default: false,
        },
        previewLimit: {
            type: Number,
            default: 3,
        },
        randomOrder: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'view-all',
    ],
    setup(props) {
        const attributes: CollectionData = {
            filters: {
                academy: true,
                exclude: props.excludeId || undefined,
            },
            include: [
                'media',
                'videosCount',
            ],
        };

        if (props.randomOrder) {
            attributes.sorts = 'inRandomOrder';
        }

        return {
            templates: ref<Templates>(new Templates([], attributes)),
            templatesContainer: ref<HTMLDivElement>(),
        };
    },
    computed: {
        hideTemplates(): boolean {
            return this.hideIfEmpty
                && !this.templates.loading
                && this.templates.isEmpty();
        },
        slicedTemplates(): Templates {
            return this.preview
                ? this.templates.slice(0, this.previewLimit)
                : this.templates;
        },
    },
    watch: {
        preview(value: boolean) {
            if (!value && this.templatesContainer) {
                fetchCollectionUntilScrollable(
                    this.templates,
                    firstScrollingParent(this.templatesContainer),
                );
            }
        },
    },
    mounted() {
        this.templates.fetch();
    },
});
